<template>
  <ion-card
    @click="() => $router.push(`/quiz/${id}`)"
    :class="inList ? 'in-list' : ''"
  >
    <ion-img class="header-img" :src="`${image}`"></ion-img>
    <ion-card-header>
      <ion-card-title>{{ title }}</ion-card-title>
      <ion-card-subtitle>{{ subtitle }}</ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      {{ description }}
    </ion-card-content>
  </ion-card>
</template>

<script>
import { inject } from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonImg,
} from "@ionic/vue";

export default {
  props: {
    id: Number,
    image: String,
    title: String,
    subtitle: String,
    description: String,
    inList: Boolean,
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonImg,
  },
  methods: {},
};
</script>

<style scoped>
ion-img::part(image) {
  max-height: 25vh;
  object-fit: cover;
}
.in-list ion-img::part(image) {
  height: 215px;
}

ion-card {
  height: 100%;
}
ion-card-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  padding-bottom: 0px;
}
</style>
