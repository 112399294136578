<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Kvízy</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="false" class="ion-content-padding">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content> </ion-refresher-content>
      </ion-refresher>
      <ion-grid fixed>
        <ion-row>
          <ion-col
            size="12"
            size-md="6"
            size-lg="4"
            size-xl="4"
            v-for="item in getQuizArray"
            :key="item.id"
          >
            <QuizCard
              :title="item.name"
              :image="item.thumb"
              :description="item.description"
              :id="item.id"
              :inList="true"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import QuizCard from "../components/QuizCard.vue";
import { mapGetters, mapActions } from "vuex";
import {
  IonGrid,
  IonPage,
  IonContent,
  IonTitle,
  IonRow,
  IonCol,
  IonHeader,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
export default {
  name: "QuizList",
  components: {
    QuizCard,
    IonGrid,
    IonPage,
    IonContent,
    IonTitle,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonRefresher,
    IonRefresherContent,
  },
  data() {
    return {
      cards: [],
    };
  },
  computed: {
    ...mapGetters({
      getQuizArray: "quiz/getQuizArray",
    }),
  },
  created() {
    // console.log(this.cards);
  },
  methods: {
    ...mapActions({
      downloadQuizArray: "quiz/downloadQuizArray",
    }),
    doRefresh(event) {
      this.downloadQuizArray().then(() => {
        event.target.complete();
      });
    },
  },
};
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
/* .ion-content-padding {
  --offset-top: -60px !important;
} */
</style>
